import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import { Helmet } from 'react-helmet';
import {useTranslation} from 'gatsby-plugin-react-i18next';
const CleanSession = () => {
  const {t} = useTranslation();
  const isSSR = typeof window === 'undefined'
  React.useEffect(()=>{
    if(!isSSR){
      sessionStorage.clear()
      localStorage.clear()
      window.location.href = '/';
    }
  },[])
  return(
    <div className="freeTrial">
      <HeardTopWrapper title={t('myKitchenInspector')} />
      <Layout>
        <Helmet>
            <title>{t('myKitchenInspector')}</title>
        </Helmet>
        <PageContainer>
          <div></div>
        </PageContainer>
      </Layout>
    </div>
  )
}
export default CleanSession
